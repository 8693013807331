import { Link } from 'react-router-dom'
import { Grid, Hidden } from '@material-ui/core'
import useCountdown from './useCountdown'

const TournamentCard = (props) => {
    
    const tournamentData = props.data
    console.log(tournamentData)

    const { timerDays, timerHours, timerMinutes, timerSeconds } = useCountdown(new Date(tournamentData.rounds[0].date/*'2024-04-04T03:55:36.726Z'*/));
    
    return (
        tournamentData &&
            <div>
                <Hidden smDown>
                    <Grid item xs={2} sm={4} md={4}>
                        <div className='tournament-card'>
                        <Link to={'/tournaments/' + tournamentData._id } style={{textDecoration:'none'}}>
                            <div className='tournament-card-background'>
                                <div style={{height:'100%'}}>
                                        <div className='tournament-card-title'>{tournamentData.name}</div>
                                        <div className='tournament-card-timer'>{timerDays < 10 ? ('0'+timerDays) : timerDays}:{timerHours < 10 ? ('0'+timerHours) : timerHours}:{timerMinutes < 10 ? ('0'+timerMinutes) : timerMinutes}:{timerSeconds < 10 ? ('0'+timerSeconds) : timerSeconds}</div>
                                        <div className={'tournament-status-'+(tournamentData.rounds[0].closed ? 'finished' : tournamentData.status)}>{ (tournamentData.rounds[0].closed ? 'finished' : tournamentData.status) }</div>
                                        <p></p>
                                        <div className='tournament-card-text'>Current Pool</div>
                                        <div className='tournament-card-title'>{ tournamentData.prizes[0].prize }</div>
                                        <p></p>
                                        {/*<button>Sign Up</button>*/}
                                        <img className='tournament-card-image' src={tournamentData.image} alt='tournament'></img>
                                </div>
                            </div>
                        </Link>
                        </div>
                    </Grid> 
                </Hidden>
                <Hidden mdUp>
                    <Grid item xs={4} sm={4} md={3}>
                        <div className='tournament-card'>
                        <Link to={'/tournaments/' + tournamentData._id } style={{textDecoration:'none'}}>
                            <div className='tournament-card-background'>
                                <div style={{height:'100%'}}>
                                        <div className='tournament-card-title'>{tournamentData.name}</div>
                                        <div className='tournament-card-timer'>{timerDays < 10 ? ('0'+timerDays) : timerDays}:{timerHours < 10 ? ('0'+timerHours) : timerHours}:{timerMinutes < 10 ? ('0'+timerMinutes) : timerMinutes}:{timerSeconds < 10 ? ('0'+timerSeconds) : timerSeconds}</div>
                                        <div className={'tournament-status-'+(tournamentData.rounds[0].closed ? 'finished' : tournamentData.status)}>{ (tournamentData.rounds[0].closed ? 'finished' : tournamentData.status) }</div>
                                        <p></p>
                                        <div className='tournament-card-text'>Current Pool</div>
                                        <div className='tournament-card-title'>{ tournamentData.prizes[0].prize }</div>
                                        <p></p>
                                        {/*<button>Sign Up</button>*/}
                                        <img className='tournament-card-image' src={tournamentData.image} alt='tournament'></img>
                                </div>
                            </div>
                        </Link>
                        </div>
                    </Grid> 
                </Hidden>
            </div>

     );
}
 
export default TournamentCard;