import  { useEffect, useRef, useState } from 'react'

const useCountdown = (date) => {

    const [timerDays, setTimerDays] = useState('0')
    const [timerHours, setTimerHours] = useState('0')
    const [timerMinutes, setTimerMinutes] = useState('0')
    const [timerSeconds, setTimerSeconds] = useState('0')

    let interval = useRef()

    const startTimer = () => {
      const countdownDate = date.getTime();

      interval = setInterval(() => {
        const now = new Date().getTime();
        const d = countdownDate - now;
        
        const days = Math.floor(d / (1000 * 60 * 60 * 24))
        const hours = Math.floor((d % (1000 * 60 * 60 * 24) / (1000 * 60 * 60)))
        const minutes = Math.floor((d % (1000 * 60 * 60)) / (1000 * 60))
        const seconds = Math.floor((d % (1000 * 60)) / 1000);
      
        if (d < 0){
          // stop
          clearInterval(interval.current);
        }
        else {
          setTimerDays(days)
          setTimerHours(hours)
          setTimerMinutes(minutes)
          setTimerSeconds(seconds)
        }
      }, 1000)
    }

    useEffect(() => {
      startTimer();
      return () => {
        clearInterval(interval.current)
      }
    })

    return { timerDays, timerHours, timerMinutes, timerSeconds }
}

export default useCountdown