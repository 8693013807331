import { useState } from "react";
import { Grid, Hidden } from "@material-ui/core";
import TournamentCard from "./tournamentCard";
import useFetch from "./useFetch";

const TournamentList = () => {
    const { data: tournaments, isPending, tournamentError } = useFetch('https://api.bloomverse.io:8103/tournaments/listall')
    const dropdownOptions = [{label: 'Upcoming', value: 'upcoming'}, {label: 'Prize Pool', value: 'prize'}, {label: 'Player Count', value: 'players'}, {label: 'Token', value: 'token'}]
    const [dropdownValue, setDropdownValue] = useState('time')
    
    const handleDropdownChange = (event) => {

        if (event.target.value === dropdownValue) return;

        switch (event.target.value) {
            case 'upcoming':
                {
                    break;
                }
            case 'prize':
                {
                    break;
                }
            case 'players':
                {
                    break;
                }
            case 'token':
                {
                    break;
                }
            default:
              return;
        }

        setDropdownValue(event.target.value);
    }
    return (
        <div>
            <Hidden smDown>
                <Grid container spacing={{ xs: 4, md: 6 }} columns={{ xs: 4, sm: 8, md: 12, }} style={{paddingLeft:'100px', }}>
                    { tournaments && tournaments.map((tournament) => (
                        <TournamentCard key={ tournament._id } data={ tournament }/> 
                    ))}
                </Grid>
            </Hidden>
            {/*<label>Sort By:
                <select value={dropdownValue} onChange={handleDropdownChange}>
                    { dropdownOptions.map((option) =>
                        <option value={option.value}>{option.label}</option>
                    )}
                </select>
                    </label>*/
            }
            <Hidden mdUp>
                <Grid container  spacing={{ xs: 4, md: 6 }} columns={{ xs: 4, sm: 8, md: 12, }} style={{justifyContent:'center'}}>
                    { tournaments && tournaments.map((tournament) => (
                        <TournamentCard key={ tournament._id } data={ tournament }/> 
                    ))}
                </Grid>
            </Hidden>
        </div>
    );
}

export default TournamentList