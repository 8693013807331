import { useState, useEffect } from "react"

const useFetch = (url) => {
  const [data, setData] = useState(null);
  const [isPending, setIsPending] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const abortController = new AbortController();
    fetch(url, { signal: abortController.signal, method:'GET'}).then(res => {
      if (!res.ok) {
        throw Error('Unable to fetch data')
      }
      return res.json()
    }).then(data => {
      setData(data)
      setIsPending(false)
      setError(null)
    }).catch(err => {
      if (!err.name === 'AbortError'){
        setIsPending(false)
        setError(err.message)
      }
    })
      return () => abortController.abort();
    }, [url]);
    return { data, isPending, error };
}

export default useFetch