import { useParams } from "react-router-dom";
import useFetch from "./useFetch";
import { Button, Grid } from "@material-ui/core";
import { DataGrid } from '@mui/x-data-grid';
import shareIcon from '../media/img/share.png'
import useCountdown from "./useCountdown";


const getTournamentGradient = (token) => {
    switch (token) {
            case 'C7SBQ7Pb8D9wbkfkvjXRCdAEukubXdYC38fp4yBTz2ue':
                {
                    return 'radial-gradient(67.31% 67.31% at 49.69% 68.98%, rgba(64, 221, 255, 0.8) 1%, rgba(0, 87, 106, 0.8) 100%, rgba(0, 87, 106, 0.8) 100%)'
                }
            case '6CNHDCzD5RkvBWxxyokQQNQPjFWgoHF94D7BmC73X6ZK':
                {
                    return 'radial-gradient(67.31% 67.31% at 49.69% 68.98%, rgba(64, 221, 255, 0.8) 1%, rgba(0, 87, 106, 0.8) 100%, rgba(0, 87, 106, 0.8) 100%)'
                }
            case 'EKpQGSJtjMFqKZ9KQanSqYXRcF8fBopzLHYxdM65zcjm':
                {
                    return 'radial-gradient(67.31% 67.31% at 49.69% 68.98%, rgba(64, 221, 255, 0.8) 1%, rgba(0, 87, 106, 0.8) 100%, rgba(0, 87, 106, 0.8) 100%)'
                }
            case '9gwTegFJJErDpWJKjPfLr2g2zrE3nL1v5zpwbtsk3c6P':
                {
                    return 'radial-gradient(67.31% 67.31% at 49.69% 68.98%, rgba(64, 221, 255, 0.8) 1%, rgba(0, 87, 106, 0.8) 100%, rgba(0, 87, 106, 0.8) 100%)'
                }
            case 'H7ed7UgcLp3ax4X1CQ5WuWDn6d1pprfMMYiv5ejwLWWU':
                {
                    return 'radial-gradient(67.31% 67.31% at 49.69% 68.98%, rgba(64, 221, 255, 0.8) 1%, rgba(0, 87, 106, 0.8) 100%, rgba(0, 87, 106, 0.8) 100%)'
                }
            case 'HhJpBhRRn4g56VsyLuT8DL5Bv31HkXqsrahTTUCZeZg4':
                {
                    return 'radial-gradient(67.31% 67.31% at 49.69% 68.98%, rgba(64, 221, 255, 0.8) 1%, rgba(0, 87, 106, 0.8) 100%, rgba(0, 87, 106, 0.8) 100%)'
                }
            default:
                return 'radial-gradient(67.31% 67.31% at 49.69% 68.98%, rgba(64, 221, 255, 0.8) 1%, rgba(0, 87, 106, 0.8) 100%, rgba(0, 87, 106, 0.8) 100%)'
        }

}

const Tournament = () => {
    
    const { id } = useParams()
    const { data: tournament, error, isPending } = useFetch('https://api.bloomverse.io:8103/tournaments/' + id)
    const tournamentColumns = [{field: 'rank', headerName: 'Rank', width: 90},
                                {field: 'nickname', headerName: 'Player', width: 270},
                                {field: 'score', headerName: 'Score', width: 90},
                                {field: 'kills', headerName: 'Kills', width: 90},
                                {field: 'deaths', headerName: 'Deaths', width: 90}]
    const { timerDays, timerHours, timerMinutes, timerSeconds } = useCountdown(new Date(/*tournament.rounds[0].date*/'2024-04-04T03:55:36.726Z'));

    console.log(tournament)
    return (tournament && 
            <div className='tournament-full'>
                <Grid container spacing={2} style={{flex:1, paddingLeft:30, paddingRight: 30}}>
                    <Grid item spacing={2} xs={6} style={{paddingLeft:50, paddingRight:100, maxWidth:800}}>
                        <div className='tournament-card-full' style={{background:getTournamentGradient(tournament.conditions[0].value)}}>
                            <div style={{ display:'flex', flexDirection:'column', alignItems:'center'}}>
                                <div style={{width:'100%', display:'flex', alignItems:'center', flexDirection:'row', justifyContent:'space-between'}}>
                                    <p style={{fontSize:'20px'}} className={'tournament-status-'+tournament.status}>{tournament.status}</p>
                                    <Button variant='text' onClick={() => {navigator.clipboard.writeText(window.location.href); }}>
                                        <img width={20} height={20} src={shareIcon}/>
                                    </Button>
                                </div>
                                <hr style={{ marginTop:'0px', color: '#fff', backgroundColor: '#fff', height: 3, width:'100%'}}/>
                                <div className='tournament-card-title-full'>{tournament.name}</div>
                                <div className='tournament-card-timer-full'>{timerDays < 10 ? ('0'+timerDays) : timerDays}:{timerHours < 10 ? ('0'+timerHours) : timerHours}:{timerMinutes < 10 ? ('0'+timerMinutes) : timerMinutes}:{timerSeconds < 10 ? ('0'+timerSeconds) : timerSeconds}</div>
                                <p></p>
                                <div>{tournament.start_date}</div>
                                <img className='tournament-card-image-full' src={tournament.image} alt='tournament'></img>
                                <p></p>
                                <div className='tournament-card-text'>Current Pool</div>
                                <div className='tournament-card-title'>{ tournament.prizes[0].prize}</div>
                                {/*<button>Sign Up</button>*/}
                                <div style={{fontSize:10, color:'#fff'}}>
                                    <a style={{color:'#fff'}} href=''>Rules and Disclaimer</a>
                                </div>
                            </div>
                        </div>
                    </Grid>
                    <Grid item xs={6}>
                        <DataGrid style={{ border:'5px solid', borderColor:'#fff', borderRadius:'20px', fontFamily:'Louis', maxWidth:720,  maxHeight: 450, backgroundColor:'#004e68', opacity:0.9, color: '#fff', border: 2, borderColor: '#fff' }} rows={tournament.players.map(({_id, nickname, score, kills, deaths}, index) => {
                            return { id: _id, rank: index + 1, nickname: nickname, score: score, kills: kills, deaths: deaths}
                        })} columns={tournamentColumns} initialState={{pagination: {paginationModel:{ pageSize: 6}}}} pageSizeOptions={[5]}/>
                        <div className='tournament-card-title'>{'Region: '+tournament.region}</div>
                        <div className='tournament-card-title'>{'Players: '+tournament.playerCount+'/'+tournament.max_players}</div>
                        <p></p>
                        <div className='tournament-card-text'>Current Pool</div>
                        <div className='tournament-card-title'>{tournament.prizes[0].prize}</div>
                    </Grid>
                </Grid>
            </div>
     );
}
 
export default Tournament;