import { useState } from "react";
import { Grid, Hidden, Modal, Backdrop, Container } from "@material-ui/core";
import { Fade } from '@material-ui/core'
import { Link } from "react-router-dom";
import teamData from './team.json'


const TeamList = (props) => {

  const GetTeamIndexById = (id) => {
    for (let i = 0; i < teamData.length; i++) {
      if (teamData[i].id === id) return {id: i};
    }
    return {id: 0}; 
  }

  let [teamModal, setTeamModal] = useState(props.data === undefined ? false : true);
  let [teamMemberInfo, setTeamMemberInfo] = useState(props.data === undefined ? {id: 0} : GetTeamIndexById(props.data.id));
  
  window.addEventListener('resize', function() {
    
  })


  const teamImage = (imgName) => {
    return require(`../media/img/team/${imgName}`)
  }

  const handleTeamModal = () => {
    setTeamModal(false);
  };
  
  const toggleCV = (index) => {
    setTeamModal(true);
    setTeamMemberInfo({ id: index });
  };

    return (
        <div className='root-div'>
          <Modal className='team-modal' aria-labelledby="team" aria-describedby="team"
            open={teamModal}
            onClose={handleTeamModal}
            closeAfterTransition
            BackdropComponent={Backdrop}
            BackdropProps= {{
              timeout: 500,
            }}
          >
            <Fade in={teamModal}>
              <div className='team-modal-fade'>
                <img alt="bloom1" title="bloom1" className='team-modal-fade-image' src={teamImage(teamData[teamMemberInfo.id].img)}/>
                <div className='team-modal-fade-name'>
                  { teamData[teamMemberInfo.id].name }
                </div>
                <div className='team-modal-fade-position'>
                  <i>{teamData[teamMemberInfo.id].role}</i>
                </div>
                <p className='team-modal-fade-desc'>
                  {teamData[teamMemberInfo.id].description}
                </p>
              </div>
            </Fade>
          </Modal>
          <Hidden smDown>
           
           <Container maxWidth={'xl'}>
            <Grid item md={12}> {/* BIG */}
              <Grid container >
                <Grid item md={12} className='content-grid-section'>
                  <Grid id='team' container className='content-grid-section-container-big' style={{marginTop:'200px'}}>
                    { teamData.map((teamMember, index) => (
                        <Grid key={teamMember.id} item sm={12} md={3} lg={3} style={{marginBottom:'30px'}}>
                          <Link to={'/team/' + teamMember.id }>
                            <img width={120} height={120} onClick={() => { toggleCV(index) }} className='team-member-img' alt={teamMember.name} src={teamImage(teamMember.img)}></img>
                          </Link>
                          <div className='team-member-name'>{teamMember.name}</div>
                          <div className='team-member-role'><i>{teamMember.role}</i></div>
                        </Grid>
                    ))}
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
            </Container>
          </Hidden>
          <Hidden mdUp>
              <Grid container>
                <Grid item md={6}>
                  <Grid id='team' container className='content-grid-section-container-small'>
                    { teamData.map((teamMember, index) => (
                        <Grid key={teamMember.id} item sm={12} md={3} lg={3} style={{marginBottom:'30px'}}>
                          <Link to={'/team/' + teamMember.id }>
                            <img width={120} height={120} onClick={() => { toggleCV(index) }} className='team-member-img' alt={teamMember.name} src={teamImage(teamMember.img)}></img>
                          </Link>
                          <div className='team-member-name'>{teamMember.name}</div>
                          <div className='team-member-role'><i>{teamMember.role}</i></div>
                        </Grid>
                    ))}
                  </Grid>
                </Grid>
              </Grid>
          </Hidden>
        </div>
    );
}

export default TeamList;