import Navbar from './components/navbar';
import Home from './components/home';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';

function App() {
  return (
    <Router>
      <div className='App'>
        <Navbar/>
        <div className='home'>
          <Routes>
            <Route exact path='/' element={<Home route={'home'}/>}/>
            <Route path='/team' element={<Home route={'team'}/>}/>
            <Route path='/team/:id' element={<Home route={'team-member'}/>}/>
            <Route path='/partners' element={<Home route={'partners'}/>}/>
            <Route path='/partners/:id' element={<Home route={'partner'}/>}/>
            <Route path='/tournaments' element={<Home route={'tournaments'}/>}/>
            <Route path='/tournaments/:id' element={<Home route={'tournament'}/>}/>
          </Routes>
        </div>
      </div>
    </Router>

  );
}

export default App;
