import { useState } from "react";
import { Grid, Hidden } from "@material-ui/core";
import partnerData from './partners.json'

const PartnerList = () => {
    
  const partnerImage = (imgName) => {
    return require(`../media/img/partners/${imgName}`)
  }
  
  return (
    <div className='root-div'>
      <Hidden smDown>
        <Grid item xs={12} sm={8}>{/*  MENU LEFT */}</Grid>
        <Grid item md={12}> {/* BIG */}
          <Grid container style={{WebkitBackdropFilter:'blur(5px)'}}>
            <Grid item md={6} className='content-grid-section'>
              <Grid id='partners' container className='content-grid-section-container-big'>
                {partnerData.map((partner, index) => (
                  <Grid item sm={12} md={2} lg={2} style={{marginBottom:'30px'}}>
                    <img width={120} height={120} className='partner-img' alt={partner.name} src={partnerImage(partner.img)}></img>
                    <div className='team-member-name'>{partner.name}</div>
                  </Grid>
                ))}
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Hidden>
      <Hidden mdUp>
        <Grid container>
          <Grid id='partners' container className='content-grid-section-container-small'>
            {partnerData.map((partner, index) => (
              <Grid item sm={12} md={2} lg={2} style={{marginBottom:'30px'}}>
                <img width={120} height={120} className='partner-img' alt={partner.name} src={partnerImage(partner.img)}></img>
                <div className='team-member-name'>{partner.name}</div>
              </Grid>
            ))}
          </Grid>
        </Grid>
      </Hidden>
    </div>
  );
}
 
export default PartnerList;